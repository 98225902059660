import { dataToURLString } from 'aspida';
var api = function (_a) {
    var baseURL = _a.baseURL, fetch = _a.fetch;
    var prefix = (baseURL === undefined ? 'https://atndhosp.net/api/v0' : baseURL).replace(/\/$/, '');
    var PATH0 = '/categories';
    var PATH1 = '/change-email';
    var PATH2 = '/chief-complaints';
    var PATH3 = '/companies';
    var PATH4 = '/contacts';
    var PATH5 = '/hospitals';
    var PATH6 = '/inquiries';
    var PATH7 = '/inquiry-choices';
    var PATH8 = '/laboratory';
    var PATH9 = '/researches';
    var PATH10 = '/list-available-chief-complaints';
    var PATH11 = '/list-available-inquiries';
    var PATH12 = '/list-hospitals-of-organization';
    var PATH13 = '/list-reservations-by-date';
    var PATH14 = '/list-reservations-by-week';
    var PATH15 = '/list-reservations-of-user';
    var PATH16 = '/list-slots';
    var PATH17 = '/login';
    var PATH18 = '/login-staff';
    var PATH19 = '/operation-hours';
    var PATH20 = '/organizations';
    var PATH21 = '/patient-search';
    var PATH22 = '/refresh-staff-token';
    var PATH23 = '/research';
    var PATH24 = '/survey';
    var PATH25 = '/survey-questions';
    var PATH26 = '/survey-section';
    var PATH27 = '/survey-statistics';
    var PATH28 = '/survey-sections';
    var PATH29 = '/survey-user-count';
    var PATH30 = '/survey-graph';
    var PATH31 = '/surveys';
    var PATH32 = '/research-consent';
    var PATH33 = '/reservations';
    var PATH34 = '/reset-password';
    var PATH35 = '/revert-status';
    var PATH36 = '/show-detailed-inquiries';
    var PATH37 = '/staffs';
    var PATH38 = '/statuses';
    var PATH39 = '/survey-answers';
    var PATH40 = '/survey-contacts';
    var PATH41 = '/survey-user';
    var PATH42 = '/user-hospital-relations';
    var PATH43 = '/users';
    var PATH44 = '/verify-email';
    var PATH45 = '/vitals';
    var GET = 'GET';
    var POST = 'POST';
    var DELETE = 'DELETE';
    var PATCH = 'PATCH';
    return {
        categories: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH0, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH0, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH0, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            post: function (option) {
                return fetch(prefix, PATH0, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH0, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH0); },
        },
        change_email: {
            post: function (option) {
                return fetch(prefix, PATH1, POST, option).send();
            },
            $post: function (option) {
                return fetch(prefix, PATH1, POST, option)
                    .send()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH1); },
        },
        chief_complaints: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH2, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH2, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH2, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            post: function (option) {
                return fetch(prefix, PATH2, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH2, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function (option) {
                return "".concat(prefix).concat(PATH2).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
            },
        },
        companies: {
            /**
             * 会社の一覧を取得
             * @returns 会社の一覧を取得
             */
            get: function (option) {
                return fetch(prefix, PATH3, GET, option).json();
            },
            /**
             * 会社の一覧を取得
             * @returns 会社の一覧を取得
             */
            $get: function (option) {
                return fetch(prefix, PATH3, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH3); },
        },
        contacts: {
            /**
             * ViewSet of Contact
             */
            post: function (option) {
                return fetch(prefix, PATH4, POST, option).send();
            },
            /**
             * ViewSet of Contact
             */
            $post: function (option) {
                return fetch(prefix, PATH4, POST, option)
                    .send()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH4); },
        },
        hospitals: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH5, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH5, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH5, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            post: function (option) {
                return fetch(prefix, PATH5, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH5, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH5); },
        },
        inquiries: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH6, "/").concat(val1);
                return {
                    /**
                     * ViewSet of Inquiry
                     */
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    /**
                     * ViewSet of Inquiry
                     */
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            post: function (option) {
                return fetch(prefix, PATH6, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH6, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH6); },
        },
        inquiry_choices: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH7, "/").concat(val1);
                return {
                    /**
                     * ViewSet of Inquiry Choices
                     */
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    /**
                     * ViewSet of Inquiry Choices
                     */
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH7, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH7, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            post: function (option) {
                return fetch(prefix, PATH7, POST, option, 'FormData').json();
            },
            $post: function (option) {
                return fetch(prefix, PATH7, POST, option, 'FormData')
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH7); },
        },
        laboratory: {
            _laboratory_alias: function (val1) {
                var prefix1 = "".concat(PATH8, "/").concat(val1);
                return {
                    researches: {
                        /**
                         * ViewSet of Research
                         */
                        get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH9), GET, option).json();
                        },
                        /**
                         * ViewSet of Research
                         */
                        $get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH9), GET, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        $path: function (option) {
                            return "".concat(prefix).concat(prefix1).concat(PATH9).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                        },
                    },
                };
            },
        },
        list_available_chief_complaints: {
            _url_alias: function (val1) {
                var prefix1 = "".concat(PATH10, "/").concat(val1);
                return {
                    /**
                     * Return available Categories/C.C.s for a Hospital
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * Return available Categories/C.C.s for a Hospital
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        list_available_inquiries: {
            _url_alias: function (val1) {
                var prefix1 = "".concat(PATH11, "/").concat(val1);
                return {
                    _ccid: function (val2) {
                        var prefix2 = "".concat(prefix1, "/").concat(val2);
                        return {
                            /**
                             * Return available inquiries for a Hospital
                             */
                            get: function (option) {
                                return fetch(prefix, prefix2, GET, option).json();
                            },
                            /**
                             * Return available inquiries for a Hospital
                             */
                            $get: function (option) {
                                return fetch(prefix, prefix2, GET, option)
                                    .json()
                                    .then(function (r) { return r.body; });
                            },
                            $path: function (option) {
                                return "".concat(prefix).concat(prefix2).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                            },
                        };
                    },
                };
            },
        },
        list_hospitals_of_organization: {
            _oailias: function (val1) {
                var prefix1 = "".concat(PATH12, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        list_reservations_by_date: {
            _date: function (val1) {
                var prefix1 = "".concat(PATH13, "/").concat(val1);
                return {
                    /**
                     * Return a list of reservations of a certain date
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * Return a list of reservations of a certain date
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        list_reservations_by_week: {
            _date: function (val1) {
                var prefix1 = "".concat(PATH14, "/").concat(val1);
                return {
                    /**
                     * Return a list of reservations of a certain week
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * Return a list of reservations of a certain week
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        list_reservations_of_user: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH15, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function (option) {
                        return "".concat(prefix).concat(prefix1).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                    },
                };
            },
        },
        list_slots: {
            _url_alias: function (val1) {
                var prefix1 = "".concat(PATH16, "/").concat(val1);
                return {
                    _ccid: function (val2) {
                        var prefix2 = "".concat(prefix1, "/").concat(val2);
                        return {
                            _date: function (val3) {
                                var prefix3 = "".concat(prefix2, "/").concat(val3);
                                return {
                                    _mode: function (val4) {
                                        var prefix4 = "".concat(prefix3, "/").concat(val4);
                                        return {
                                            /**
                                             * Return reservation slots between start date and end date.
                                             */
                                            get: function (option) {
                                                return fetch(prefix, prefix4, GET, option).json();
                                            },
                                            /**
                                             * Return reservation slots between start date and end date.
                                             */
                                            $get: function (option) {
                                                return fetch(prefix, prefix4, GET, option)
                                                    .json()
                                                    .then(function (r) { return r.body; });
                                            },
                                            $path: function () { return "".concat(prefix).concat(prefix4); },
                                        };
                                    },
                                };
                            },
                        };
                    },
                };
            },
        },
        login: {
            /**
             * @returns ログイン処理(ユーザ)
             */
            get: function (option) {
                return fetch(prefix, PATH17, GET, option).json();
            },
            /**
             * @returns ログイン処理(ユーザ)
             */
            $get: function (option) {
                return fetch(prefix, PATH17, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH17); },
        },
        login_staff: {
            post: function (option) {
                return fetch(prefix, PATH18, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH18, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH18); },
        },
        operation_hours: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH19, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH19, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH19, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            post: function (option) {
                return fetch(prefix, PATH19, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH19, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH19); },
        },
        organizations: {
            get: function (option) {
                return fetch(prefix, PATH20, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH20, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH20); },
        },
        patient_search: {
            _hospital_id: function (val1) {
                var prefix1 = "".concat(PATH21, "/").concat(val1);
                return {
                    _patient_id: function (val2) {
                        var prefix2 = "".concat(prefix1, "/").concat(val2);
                        return {
                            get: function (option) {
                                return fetch(prefix, prefix2, GET, option).json();
                            },
                            $get: function (option) {
                                return fetch(prefix, prefix2, GET, option)
                                    .json()
                                    .then(function (r) { return r.body; });
                            },
                            $path: function () { return "".concat(prefix).concat(prefix2); },
                        };
                    },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH21, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH21, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function (option) {
                return "".concat(prefix).concat(PATH21).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
            },
        },
        refresh_staff_token: {
            post: function (option) {
                return fetch(prefix, PATH22, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH22, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH22); },
        },
        research: {
            _research_alias: function (val1) {
                var prefix1 = "".concat(PATH23, "/").concat(val1);
                return {
                    survey: {
                        _survey_alias: function (val3) {
                            var prefix3 = "".concat(prefix1).concat(PATH24, "/").concat(val3);
                            return {
                                survey_questions: {
                                    /**
                                     * 研究用の設問の一覧を取得
                                     */
                                    get: function (option) {
                                        return fetch(prefix, "".concat(prefix3).concat(PATH25), GET, option).json();
                                    },
                                    /**
                                     * 研究用の設問の一覧を取得
                                     */
                                    $get: function (option) {
                                        return fetch(prefix, "".concat(prefix3).concat(PATH25), GET, option)
                                            .json()
                                            .then(function (r) { return r.body; });
                                    },
                                    $path: function (option) {
                                        return "".concat(prefix).concat(prefix3).concat(PATH25).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                                    },
                                },
                                survey_section: {
                                    _survey_section_alias: function (val5) {
                                        var prefix5 = "".concat(prefix3).concat(PATH26, "/").concat(val5);
                                        return {
                                            survey_statistics: {
                                                /**
                                                 * 設問の統計の一覧を取得
                                                 * @returns research_alias&survey_alias&survey_section_aliasに紐づく設問ごとの選択問題の回答割合、数値回答問題の平均値を取得
                                                 */
                                                get: function (option) {
                                                    return fetch(prefix, "".concat(prefix5).concat(PATH27), GET, option).json();
                                                },
                                                /**
                                                 * 設問の統計の一覧を取得
                                                 * @returns research_alias&survey_alias&survey_section_aliasに紐づく設問ごとの選択問題の回答割合、数値回答問題の平均値を取得
                                                 */
                                                $get: function (option) {
                                                    return fetch(prefix, "".concat(prefix5).concat(PATH27), GET, option)
                                                        .json()
                                                        .then(function (r) { return r.body; });
                                                },
                                                $path: function (option) {
                                                    return "".concat(prefix).concat(prefix5).concat(PATH27).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                                                },
                                            },
                                        };
                                    },
                                },
                                survey_sections: {
                                    /**
                                     * セクションの一覧を取得
                                     * @returns セクションの一覧
                                     */
                                    get: function (option) {
                                        return fetch(prefix, "".concat(prefix3).concat(PATH28), GET, option).json();
                                    },
                                    /**
                                     * セクションの一覧を取得
                                     * @returns セクションの一覧
                                     */
                                    $get: function (option) {
                                        return fetch(prefix, "".concat(prefix3).concat(PATH28), GET, option)
                                            .json()
                                            .then(function (r) { return r.body; });
                                    },
                                    $path: function (option) {
                                        return "".concat(prefix).concat(prefix3).concat(PATH28).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                                    },
                                },
                                survey_user_count: {
                                    /**
                                     * 研究用の設問の回答者数を取得
                                     * @returns 研究用の設問の回答者数を取得
                                     */
                                    get: function (option) {
                                        return fetch(prefix, "".concat(prefix3).concat(PATH29), GET, option).json();
                                    },
                                    /**
                                     * 研究用の設問の回答者数を取得
                                     * @returns 研究用の設問の回答者数を取得
                                     */
                                    $get: function (option) {
                                        return fetch(prefix, "".concat(prefix3).concat(PATH29), GET, option)
                                            .json()
                                            .then(function (r) { return r.body; });
                                    },
                                    $path: function (option) {
                                        return "".concat(prefix).concat(prefix3).concat(PATH29).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                                    },
                                },
                            };
                        },
                    },
                    survey_graph: {
                        _question_alias: function (val3) {
                            var prefix3 = "".concat(prefix1).concat(PATH30, "/").concat(val3);
                            return {
                                /**
                                 * グラフデータを取得
                                 * @returns 設問ごとの回答割合、平均値を取得
                                 */
                                get: function (option) {
                                    return fetch(prefix, prefix3, GET, option).json();
                                },
                                /**
                                 * グラフデータを取得
                                 * @returns 設問ごとの回答割合、平均値を取得
                                 */
                                $get: function (option) {
                                    return fetch(prefix, prefix3, GET, option)
                                        .json()
                                        .then(function (r) { return r.body; });
                                },
                                $path: function () { return "".concat(prefix).concat(prefix3); },
                            };
                        },
                    },
                    surveys: {
                        /**
                         * 調査の一覧を取得
                         */
                        get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH31), GET, option).json();
                        },
                        /**
                         * 調査の一覧を取得
                         */
                        $get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH31), GET, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        $path: function (option) {
                            return "".concat(prefix).concat(prefix1).concat(PATH31).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                        },
                    },
                };
            },
        },
        research_consent: {
            _research_consent_id: function (val1) {
                var prefix1 = "".concat(PATH32, "/").concat(val1);
                return {
                    /**
                     * ViewSet of Research Consent
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * ViewSet of Research Consent
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    /**
                     * ViewSet of Research Consent
                     */
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    /**
                     * ViewSet of Research Consent
                     */
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            /**
             * ViewSet of Research Consent
             */
            post: function (option) {
                return fetch(prefix, PATH32, POST, option).json();
            },
            /**
             * ViewSet of Research Consent
             */
            $post: function (option) {
                return fetch(prefix, PATH32, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH32); },
        },
        reservations: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH33, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH33, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH33, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            post: function (option) {
                return fetch(prefix, PATH33, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH33, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH33); },
        },
        reset_password: {
            post: function (option) {
                return fetch(prefix, PATH34, POST, option).send();
            },
            $post: function (option) {
                return fetch(prefix, PATH34, POST, option)
                    .send()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH34); },
        },
        revert_status: {
            _rid: function (val1) {
                var prefix1 = "".concat(PATH35, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        show_detailed_inquiries: {
            _rid: function (val1) {
                var prefix1 = "".concat(PATH36, "/").concat(val1);
                return {
                    /**
                     * Return detailed inquiries of a reservation
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * Return detailed inquiries of a reservation
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        staffs: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH37, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            get: function (option) {
                return fetch(prefix, PATH37, GET, option).json();
            },
            $get: function (option) {
                return fetch(prefix, PATH37, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            post: function (option) {
                return fetch(prefix, PATH37, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH37, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH37); },
        },
        statuses: {
            post: function (option) {
                return fetch(prefix, PATH38, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH38, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH38); },
        },
        survey_answers: {
            _survey_answer_id: function (val1) {
                var prefix1 = "".concat(PATH39, "/").concat(val1);
                return {
                    /**
                     * 研究用の設問の回答を取得
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * 研究用の設問の回答を取得
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    /**
                     * 研究用の設問の回答を更新
                     */
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    /**
                     * 研究用の設問の回答を更新
                     */
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        survey_contacts: {
            /**
             * 研究用の設問に関する問い合わせ用API
             */
            post: function (option) {
                return fetch(prefix, PATH40, POST, option).send();
            },
            /**
             * 研究用の設問に関する問い合わせ用API
             */
            $post: function (option) {
                return fetch(prefix, PATH40, POST, option)
                    .send()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH40); },
        },
        survey_questions: {
            _question_id: function (val1) {
                var prefix1 = "".concat(PATH25, "/").concat(val1);
                return {
                    /**
                     * 研究用の設問の詳細を取得
                     * @returns 設問の詳細を取得
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * 研究用の設問の詳細を取得
                     * @returns 設問の詳細を取得
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
        survey_user: {
            _survey_user_id: function (val1) {
                var prefix1 = "".concat(PATH41, "/").concat(val1);
                return {
                    survey_answers: {
                        /**
                         * 研究用の設問の回答を登録
                         * @returns 成功
                         */
                        post: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH39), POST, option).json();
                        },
                        /**
                         * 研究用の設問の回答を登録
                         * @returns 成功
                         */
                        $post: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH39), POST, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        /**
                         * 研究用の設問の回答を取得
                         */
                        get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH39), GET, option).json();
                        },
                        /**
                         * 研究用の設問の回答を取得
                         */
                        $get: function (option) {
                            return fetch(prefix, "".concat(prefix1).concat(PATH39), GET, option)
                                .json()
                                .then(function (r) { return r.body; });
                        },
                        $path: function (option) {
                            return "".concat(prefix).concat(prefix1).concat(PATH39).concat(option && option.query ? "?".concat(dataToURLString(option.query)) : '');
                        },
                    },
                };
            },
            _survey_user_id_string: function (val1) {
                var prefix1 = "".concat(PATH41, "/").concat(val1);
                return {
                    /**
                     * 研究参加者の詳細を取得
                     * @returns 研究参加者の詳細を取得
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * 研究参加者の詳細を取得
                     * @returns 研究参加者の詳細を取得
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    /**
                     * 研究参加者の更新
                     * @returns 研究参加者の更新
                     */
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    /**
                     * 研究参加者の更新
                     * @returns 研究参加者の更新
                     */
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            /**
             * 研究参加者の登録
             */
            post: function (option) {
                return fetch(prefix, PATH41, POST, option).json();
            },
            /**
             * 研究参加者の登録
             */
            $post: function (option) {
                return fetch(prefix, PATH41, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH41); },
        },
        user_hospital_relations: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH42, "/").concat(val1);
                return {
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            /**
             * ViewSet of User Hospital Relation
             */
            get: function (option) {
                return fetch(prefix, PATH42, GET, option).json();
            },
            /**
             * ViewSet of User Hospital Relation
             */
            $get: function (option) {
                return fetch(prefix, PATH42, GET, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            /**
             * ViewSet of User Hospital Relation
             */
            post: function (option) {
                return fetch(prefix, PATH42, POST, option).json();
            },
            /**
             * ViewSet of User Hospital Relation
             */
            $post: function (option) {
                return fetch(prefix, PATH42, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH42); },
        },
        users: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH43, "/").concat(val1);
                return {
                    get: function (option) {
                        return fetch(prefix, prefix1, GET, option).json();
                    },
                    $get: function (option) {
                        return fetch(prefix, prefix1, GET, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option).send();
                    },
                    $delete: function (option) {
                        return fetch(prefix, prefix1, DELETE, option)
                            .send()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
            post: function (option) {
                return fetch(prefix, PATH43, POST, option).json();
            },
            $post: function (option) {
                return fetch(prefix, PATH43, POST, option)
                    .json()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH43); },
        },
        verify_email: {
            post: function (option) {
                return fetch(prefix, PATH44, POST, option).send();
            },
            $post: function (option) {
                return fetch(prefix, PATH44, POST, option)
                    .send()
                    .then(function (r) { return r.body; });
            },
            $path: function () { return "".concat(prefix).concat(PATH44); },
        },
        vitals: {
            _id: function (val1) {
                var prefix1 = "".concat(PATH45, "/").concat(val1);
                return {
                    patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option).json();
                    },
                    $patch: function (option) {
                        return fetch(prefix, prefix1, PATCH, option)
                            .json()
                            .then(function (r) { return r.body; });
                    },
                    $path: function () { return "".concat(prefix).concat(prefix1); },
                };
            },
        },
    };
};
export default api;
